body {
  height: 100vh;
}

#root {
  height: 100%;
}

.app {
  height: 100%;
}

button {
  background-color: black;
  border: 0;
  color: white;
}

.mode-switcher {
  text-align: center;
}

.mode-switcher button {
  margin: 0.5rem;
  font-size: 2rem;
}

.mode-switcher .selected {
  background-color: green;
}

.readme {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90%;
}

.readme div {
  position: relative;
  overflow-y: scroll;
  height: 100%;
  width: 90%;
}
