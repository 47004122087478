.qr-reader {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  max-height: 70%;
}

.qr-box {
  width: 80%;
}

.qr-box video {
  width: 100%;
  height: 60vh;
}

.progressbar {
  display: flex;
  margin-top: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 1rem;
}

.progressbar .slice {
  width: 8px;
  height: 1rem;
  border-color: gray;
  border-width: 1px;
}

.progressbar .available {
  background-color: green;
}

.progressbar .missing {
  background-color: red;
}

.receiver-indication {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: absolute;
  color: cyan;
  background-color: gray;
  z-index: 600;
  padding: 5px;
  background-color: rgba(90, 90, 200, 0.5);
  margin-left: 10%;
  margin-top: 10%;
}

.info-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.info-box > * {
  width: 80%;
}
